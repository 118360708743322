import { BrowserRouter as Router ,Route,Routes} from "react-router-dom";
import About from "./component/About";
import Contact from "./component/Contact";
import Docter from "./component/Docter";
import Fter from "./component/Fter";
import Gallery from "./component/Gallery";
import Main from "./component/Main";
import Navbar from "./component/Navbar";
import Services from "./component/Services";
import Appointment from "./Pages/Appointment";
import Adminappointment from "./Pages/Adminappointment";
import AdminLogin from "./Pages/AdminLogin";
import Edit from "./Pages/Edit";
import PatientDashboard from "./Pages/PatientDashboard";
import { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BioMedicalWaste from "./component/BioMedicalWaste";
import PrivateRoute from "./component/PrivateRoute";
import { AuthContextProvider } from "./Pages/AuthContext";

function App() {
  const [patientId, setPatientId] = useState();

  const getPatientIdHandler = (id) => {
    setPatientId(id);
  };
                  
  return (
    <>
      <Router>
        <Navbar/>
        <AuthContextProvider>
        <Routes>
          <Route exact path="/" element={<Main />} />
          <Route exact path="/About" element={<About />} />
          <Route exact path="/Services" element={<Services />} />
          <Route exact path="/Services" element={<Services />} />
          <Route exact path="/Contact" element={<Contact />} />
          <Route exact path="/Gallery" element={<Gallery />} />
          <Route exact path="/Docter" element={<Docter />} />
          <Route exact path="/BioMedicalWaste" element={<BioMedicalWaste />} />
          <Route exact path="/Appointment" element={<Appointment />} />
          <Route exact path="/AdminLogin" element={<AdminLogin/>} />
          <Route  path="/PatientDashboard" element={<PrivateRoute><PatientDashboard getPatientId={getPatientIdHandler}/></PrivateRoute>}/>
          <Route exact path="/Edit" element={<PrivateRoute><Edit id={patientId} setPatientId={setPatientId} /></PrivateRoute>} />
          <Route exact path="/Adminappointment" element={<PrivateRoute><Adminappointment /></PrivateRoute>} />
          <Route exact path="*" element={<h1>Error 404 Page not found!!</h1>} />
        </Routes>
        </AuthContextProvider>
        <ToastContainer />
        <Fter />
      </Router>
    </>
  );
}

export default App;

