import React from 'react'
import "./App.css";
import { NavLink,Link } from 'react-router-dom';
import { HiOutlineArrowSmRight } from 'react-icons/hi';
import Ticker from './Ticker';

export default function Main() {
  // const navigate = useNavigate();

  return (
    <>

      <div className="divbar">

<h1>Abhinav Nursing Home</h1>
 <h5>Delivering Quality Healthcare and Smiles...</h5>
<NavLink to='/Appointment' > <button type="button" className="btn-divbar"> Book Appointment </button></NavLink>
</div>
    
      <section className='services'>
        <Ticker/>
        <div className="div-p"><p>  Abhinav Nursing home (ANH) is located in heart of city , Rajwada.

          It's easily located from all directions of Indore.  ANH provides nursing & maternity facilities in affordable rates. The presence of Doctors & friendly staff round the clock ensures proper clinical care & empathy with patient. We provide afforadable patient care & our basic services include clinical checkup, health check ups , general surgery , delivery .


        </p>

        <li>< Link to='/Docter'> Meet our Doctors <HiOutlineArrowSmRight /></ Link></li>
        </div>
        <div className="flex">
          <div className="card1 ">
            <img src="\assets\images\download (1).jpg" alt="Blood " />
            <h3 className='text-center my-2'> Blood pressure</h3>
          </div>
          <div className="card1 ">
            <img src="\assets\images\heart.gif" alt="heart " />

            <h3 className='text-center my-2'> Heart </h3>
          </div>
          <div className="card1 ">
            <img src="\assets\images\download (2).jpg" alt="Diabetes" />
            <h3 className='text-center my-2'>  Diabetes</h3>
          </div>


          <div className="card1 ">
            <img src="/assets/images/mom1.jpg" alt="mom" />
            <h3 className='text-center my-2'> Baby / Gynacelogy</h3>
          </div>
        </div>


      </section>
    </>
  )
}
