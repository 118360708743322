import React, { useState } from 'react'
import {useNavigate } from 'react-router-dom';
import {toast } from 'react-toastify';
import { UserAuth } from './AuthContext';




function AdminLogin() {

    const [email,setEmail]=useState("");
    const [password,setPassword]=useState("");
    const navitage = useNavigate()
    const {signIn}=UserAuth()


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await signIn(email,password)

      toast.success("successfuly Login",{
        position: toast.POSITION.TOP_CENTER
      })
      navitage("/PatientDashboard")
      
    } catch (e) {
        toast.error('Wrong Email and Password', {
            position: toast.POSITION.TOP_CENTER
        });
    }
  };

  
  return (
    <>
   
    <div className='login'>
    
      <form className='form1' onSubmit={handleSubmit} >
      <label htmlFor='email'>Email</label>
      <input className='email1' type='email' id='email' placeholder='Email' onChange={(e) => setEmail(e.target.value)} required/><br/>
      <label htmlFor='password'>Password</label>
      <input type='password' id='password' className='password' placeholder='Password' onChange={(e) => setPassword(e.target.value)} required/><br/>
      <button className='btn' type='submit' >Submit</button>
    </form>
    
  </div>

    </>
  )
}

export default AdminLogin