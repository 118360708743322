import React, { useState, useEffect } from "react";
import patientDataService from "../Services/patientServices";
import { Link } from "react-router-dom";

function PatientDashboard({ getPatientId }) {
  const [patients, setPatients] = useState([]);
  const [searchDate, setSearchDate] = useState(
    localStorage.getItem("searchDate") || ""
  );
  const [searchMobile, setSearchMobile] = useState(
    localStorage.getItem("searchMobile") || ""
  );
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    getPatients();
  }, [currentPage, searchDate, searchMobile, selectedFilter]);

  useEffect(() => {
    localStorage.setItem("searchDate", searchDate);
    localStorage.setItem("searchMobile", searchMobile);
  }, [searchDate, searchMobile]);

  const getPatients = async () => {
    try {
      const data = await patientDataService.getAllPatient();
      const filteredPatients = data.docs
        .map((doc) => ({ ...doc.data(), id: doc.id }))
        .filter(
          (patient) =>
            patient.status === selectedFilter || selectedFilter === "All"
        )
        .filter(
          (patient) => patient.date.includes(searchDate) || searchDate === ""
        )
        .filter(
          (patient) =>
            patient.phone.includes(searchMobile) || searchMobile === ""
        )
        .sort((a, b) => new Date(b.date) - new Date(a.date));

      setTotalPages(Math.ceil(filteredPatients.length / 10)); 
      setPatients(
        filteredPatients.slice((currentPage - 1) * 10, currentPage * 10)
      );
    } catch (error) {
      console.error("Error fetching Patients:", error);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const filterPatientsByStatus = async (status) => {
    try {
      const data = await patientDataService.getAllPatient();
      const filteredPatients = data.docs
        .map((doc) => ({ ...doc.data(), id: doc.id }))
        .filter((patient) => patient.status === status)
        .sort((a, b) => new Date(b.date) - new Date(a.date));
      setPatients(filteredPatients);
    } catch (error) {
      console.error("Error filtering Patients by status:", error);
    }
  };

  const handleFilter = async () => {
    if (selectedFilter === "Visited") {
      filterPatientsByStatus("visited");
    } else if (selectedFilter === "Not-visited") {
      filterPatientsByStatus("Not-visited");
    } else if (selectedFilter === "Requested") {
      filterPatientsByStatus("Requested");
    } else {
      getPatients();
    }
    setSearchDate("");
    setSearchMobile("");
  };

  return (
    <>
      <div className="site">
        <select
          className="content"
          value={selectedFilter}
          onClick={handleFilter}
          onChange={(e) => setSelectedFilter(e.target.value)}
        >
          <option value="All">All</option>
          <option value="Visited">Visited</option>
          <option value="Not-visited">Not Visited</option>
          <option value="Requested">Requested</option>
        </select>
        <Link to="/adminAppointment">
          {" "}
          <button className="btn1">Book New Appointment</button>
        </Link>
        <input
          type="date"
          className="content"
          value={searchDate}
          onChange={(e) => setSearchDate(e.target.value)}
        />
        <input
          type="text"
          className="content"
          placeholder="Search by Mobile No."
          value={searchMobile}
          onChange={(e) => setSearchMobile(e.target.value)}
        />
        <Link to="/adminlogin">
          {" "}
          <button className="btn3">Logout</button>
        </Link>
      </div>
      <div className="pagination">
        {Array.from(Array(totalPages).keys()).map((pageNumber) => (
          <button
            key={pageNumber + 1}
            className={`btn4 ${currentPage === pageNumber + 1 ? "active" : ""}`}
            onClick={() => handlePageChange(pageNumber + 1)}
          >
            {pageNumber + 1}
          </button>
        ))}
      </div>

      <div className="table table-striped-columns">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">S.No.</th>
              <th scope="col">NAME</th>
              <th scope="col">EMAIL</th>
              <th scope="col">ADDRESS</th>
              <th scope="col">AGE</th>
              <th scope="col">GENDER</th>
              <th scope="col">SLOT</th>
              <th scope="col">DATE</th>
              <th scope="col">SERVICE</th>
              <th scope="col">MOBILE NO.</th>
              <th scope="col">STATUS</th>
              <th scope="col">DOCTORS</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {patients.map((doc, index) => {
              return (
                <tr key={doc.id}>
                  <td>{index + 1}</td>
                  <td>{doc.name}</td>
                  <td>{doc.email}</td>
                  <td>{doc.address}</td>
                  <td>{doc.age}</td>
                  <td>{doc.gender}</td>
                  <td>{doc.slot}</td>
                  <td>{doc.date}</td>
                  <td>{doc.services}</td>
                  <td>{doc.phone}</td>
                  <td>{doc.status}</td>
                  <td>{doc.selectDoctor}</td>
                  <td className="btn2">
                    <Link className="link " to="/edit">
                      <button
                        className="btn btn-success"
                        onClick={(e) => getPatientId(doc.id)}
                      >
                        Edit
                      </button>
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}
export default PatientDashboard;
