import React from 'react'
import "./hos.css"
import { NavLink } from "react-router-dom";
import Ticker from './Ticker';
export default function Docter() {
  return (
    <>   
      <div className="space"></div>


      <Ticker />
      <div className="divcard">
        <div className="cardfirst">
          <div className="cardimage">
            <img src="\assets\images\sm holkar.jpg" alt=" SM holkar" />
          </div>
          <h4> Dr S.M. Holkar</h4>
          <div className="pra">
            <p>Dr S.M. Holkar has more than 40 years of experience in field of General Medicine and cardiology. Dr Holkar has been associated with various esteem organisations like Ashirwad Drug deaddiction, Index medical college & Ankur Rehab. He currently is Medical Director at Index Medical college.
              Dr Holkar has been cured many patients with health problems ranging from diabetes, blood pressure, cardio-vasuclar & general health.
              He is available in evening clinic & some times in morning by appointmnent.</p>
          </div>

          <li><NavLink  to='/Appointment'> <button type="button" className="btn btn-primary margin-auto btnup"> Book appointment </button></NavLink></li>
        </div>
        <div className="cardfirst">
          <div className="cardimage">
            <img src="\assets\images\IMG_20220712_115116.jpg" alt="" />
          </div>
          <h4>Dr Sandhya Holkar</h4>
          <div className="pra">
            <p> Dr Sandhya Holkar has more than 40 years of experience in gynacology and women's health. Doctor Sandhya, has performed many successful deliveries & have helped patients to overcome pregnancy related issues. She was associated with Guara Devi Maternity home earlier.
              She is available in morning clinic  & sometimes in evening by appointmnent. </p>
          </div>
          <li><NavLink to='/Appointment'> <button type="button" className="btn btn-primary margin-auto btnup"> Book appointment </button></NavLink></li>

        </div>
      </div></>
  )
}
